<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/structure' }">
        <app-i18n code="entities.structure.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.structure.view.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.structure.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-structure-view-toolbar
        v-if="record"
      ></app-structure-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.id.label"
          :value="presenter(record, 'id')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.structureName.label"
          :value="presenter(record, 'structureName')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.statusStructure.label"
          :value="presenter(record, 'statusStructure')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.breakBefore.label"
          :value="presenter(record, 'breakBefore')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.breakLimit.label"
          :value="presenter(record, 'breakLimit')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
      <div v-if="record">
        <app-struct-item-list-table
          :IsViewMode="true"
        ></app-struct-item-list-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import StructureViewToolbar from '@/modules/structure/components/structure-view-toolbar.vue';
import StructItemListTable from '@/modules/structure/components/struct-item-list-table.vue';
//import StructItemListToolbar from '@/modules/structure/components/struct-item-list-toolbar.vue';

import { StructureModel } from '@/modules/structure/structure-model';

const { fields } = StructureModel;

export default {
  name: 'app-structure-view-page',

  props: ['id'],

  components: {
    [StructureViewToolbar.name]: StructureViewToolbar,
    [StructItemListTable.name]: StructItemListTable,
    //[StructItemListToolbar.name]: StructItemListToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'structure/view/record',
      loading: 'structure/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'structure/view/doFind',
    }),

    presenter(record, fieldName) {
      return StructureModel.presenter(record, fieldName);
    },
  },
};
</script>
